import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MdsSelectionList } from '@medpacesoftwaredevelopment/designsystem';
import { ClinTrakStudyVisitViewModel } from '@models/clintrak';
import { Study, StudyMappedVisitDto, VisitName } from '@models/study';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { filter, switchMap, take, tap } from 'rxjs';

export interface ClinTrakStudyVisit {
    id: string;
    name: string;
    description: string;
    comment: string;
    displayOrder: number;
}

export interface MappedVisit {
    pcsVisitName: string;
    clintrakStudyVisits: ClinTrakStudyVisit[];
}

@Component({
    selector: 'medpace-visit-mapping-modal',
    templateUrl: './medpace-visit-mapping-modal.component.html',
    styleUrls: ['./medpace-visit-mapping-modal.component.scss'],
})
export class MedpaceVisitMappingModalComponent implements OnInit {
    title: string;
    bodyText: string;
    study: Study;
    loaderSpinner: boolean = true;
    okayButtonLabel: string;
    cancelButtonLabel: string;
    showCancelButton: boolean = true;
    showBodyText: boolean = true;
    selectedClintrakVisits: string[] = [];
    selectedClintrakVisitsGroupName: string;
    selectedPcsVisit: string = null;
    mappedVisits: MappedVisit[] = [];
    studyMappedVisits: StudyMappedVisitDto[] = [];
    currentPcsVisits: VisitName[];
    clinTrakVisits: ClinTrakStudyVisitViewModel[];
    clinTrakVisitsForView: MdsSelectionList[] = [];
    pcsVisitsFormArray: FormArray<FormControl<boolean>>;
    baseFormGroup: FormGroup;
    clinTrakVisitsPerGroups: { [groupName: string]: ClinTrakStudyVisitViewModel[] } = {};
    clinTrakVisitsPerGroupsForView: { [groupName: string]: MdsSelectionList[] } = {};
    notSelectedVisitsAlert: boolean = false;

    vm$ = this.studyStateService.getStudy().pipe(
        take(1),
        tap((study) => {
            this.study = study;

            this.currentPcsVisits = this.data?.visits;

            if (this.currentPcsVisits) {
                this.currentPcsVisits.forEach((vis) => {
                    let mappedVis = <StudyMappedVisitDto>{ visitName: vis };
                    if (this.study.studyInfo.studyMappedVisits && this.study.studyInfo.studyMappedVisits.length > 0) {
                        mappedVis.patientVisitsMaster = this.study.studyInfo.studyMappedVisits.find(
                            (x) => x.visitName.name === vis.name && x.visitName.visitGroup.id === vis.visitGroup.id
                        )?.patientVisitsMaster;
                    }
                    this.studyMappedVisits.push(mappedVis);
                });
            }
        }),
        filter((study) => !!study),
        switchMap((study) => this.clinTrakDataService.getStudyVisits(study.studyCode)),
        tap({
            next: (ctV) => {
                this.clinTrakVisits = ctV;

                ctV.map((x) => {
                    let arrays = this.studyMappedVisits.map((z) => z.patientVisitsMaster);
                    let mergedPatientVisitsMaster = <ClinTrakStudyVisitViewModel[]>[].concat(...arrays);

                    if (!mergedPatientVisitsMaster?.map((z) => z?.visitName).includes(x.visitName)) {
                        let visitForList = <MdsSelectionList>{
                            value: x.patientVisitMasterId,
                            viewValue: x.visitName,
                        };

                        this.clinTrakVisitsForView.push(visitForList);
                    }
                });

                this.studyMappedVisits.forEach((v) => {
                    this.clinTrakVisitsPerGroups[v.visitName.visitGroup.name] = this.clinTrakVisits;
                    this.clinTrakVisitsPerGroupsForView[v.visitName.visitGroup.name] = this.clinTrakVisitsForView;
                });
            },
            complete: () => (this.loaderSpinner = false),
        })
    );

    constructor(
        private dialogRef: MatDialogRef<MedpaceVisitMappingModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            visits: VisitName[];
        },
        private studyStateService: StudyStateService,
        private clinTrakDataService: ClinTrakDataService,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initFormGroup();
    }

    initFormGroup() {
        this.baseFormGroup = new FormGroup({});
        this.pcsVisitsFormArray = new FormArray([]);

        this.data.visits.forEach((visit) => {
            let newCtrl = new FormControl<boolean>(false);

            if (this.baseFormGroup.contains(`${visit.visitGroup.name}FormGroup`)) {
                let formGroupPerName = this.baseFormGroup.controls[`${visit.visitGroup.name}FormGroup`] as FormGroup;
                let newControl = new FormControl<boolean>(false);
                let formArray = formGroupPerName.controls.pcsVisitsArray as FormArray<FormControl<boolean>>;
                formArray.push(newControl);
            } else {
                let formArray = new FormArray([]);
                formArray.push(newCtrl);

                let formGroupByName = this.formBuilder.group({
                    pcsVisitsArray: formArray,
                });

                this.baseFormGroup.addControl(`${visit.visitGroup.name}FormGroup`, formGroupByName);
            }
        });
    }

    getClinTrakVisitsPerGroupName(groupName: string): MdsSelectionList[] {
        let result = this.clinTrakVisitsPerGroupsForView[groupName];
        return result;
    }

    getVisitsGroupName(): string[] {
        let groupNames = this.data.visits.map((x) => x.visitGroup.name);
        let uniqueGroupNames = [...new Set(groupNames)];
        return uniqueGroupNames;
    }

    getStudyMappedVisitsByGroupName(groupName: string): StudyMappedVisitDto[] {
        return this.studyMappedVisits.filter((x) => x.visitName.visitGroup.name == groupName);
    }

    clinTrakVisitsSelectionChange(event: MatSelectionListChange, groupName: string) {
        this.selectedClintrakVisits = event.source._value;
        this.selectedClintrakVisitsGroupName = groupName;
    }

    mapSelectedClinTrakVisits() {
        if (this.selectedClintrakVisits?.length === 0 || !this.selectedPcsVisit) {
            this.notSelectedVisitsAlert = true;
            return;
        }
        let ctvisits: ClinTrakStudyVisit[] = [];
        this.selectedClintrakVisits.forEach((visit) => {
            let actionItem = this.clinTrakVisitsPerGroupsForView[this.selectedClintrakVisitsGroupName].find(
                (x) => x.value == visit
            );
            let visitCt = <ClinTrakStudyVisit>{ id: visit, name: actionItem.viewValue };
            ctvisits.push(visitCt);
        });

        let pcsVisit = this.studyMappedVisits.find(
            (x) =>
                x.visitName.name == this.selectedPcsVisit &&
                x.visitName.visitGroup.name == this.selectedClintrakVisitsGroupName
        );

        let smvDto = <StudyMappedVisitDto>{
            studyId: this.study.id,
            visitName: this.currentPcsVisits.find(
                (x) => x.name == pcsVisit.visitName.name && x.visitGroup.name == this.selectedClintrakVisitsGroupName
            ),
            patientVisitsMaster: this.clinTrakVisits.filter((x) => ctvisits.map((z) => z.name).includes(x.visitName)),
        };

        if (pcsVisit.patientVisitsMaster && pcsVisit.patientVisitsMaster.length > 0) {
            pcsVisit.patientVisitsMaster = pcsVisit.patientVisitsMaster.concat(smvDto.patientVisitsMaster);
        } else pcsVisit.patientVisitsMaster = smvDto.patientVisitsMaster;

        this.removeSelectedVisitsFromList();
    }

    removeSelectedVisitsFromList() {
        const filteredVisits = this.clinTrakVisitsPerGroupsForView[this.selectedClintrakVisitsGroupName].filter(
            (x) => !this.selectedClintrakVisits.includes(<string>x.value)
        );
        this.clinTrakVisitsPerGroupsForView[this.selectedClintrakVisitsGroupName] = filteredVisits;
    }

    removeSingleClinTrakMappedVisit(studyVisitModel: StudyMappedVisitDto, visitNameToRemove: string) {
        let index = studyVisitModel.patientVisitsMaster.findIndex((x) => x.visitName == visitNameToRemove);
        const deletedVisit = studyVisitModel.patientVisitsMaster[index];
        studyVisitModel.patientVisitsMaster.splice(index, 1);
        let itemToAdd = <MdsSelectionList>{
            value: deletedVisit.patientVisitMasterId,
            viewValue: deletedVisit.visitName,
        };
        this.clinTrakVisitsPerGroupsForView[studyVisitModel.visitName.visitGroup.name].push(itemToAdd);
    }

    clearMapping(mappedVisit: StudyMappedVisitDto) {
        let ctVisits = mappedVisit.patientVisitsMaster;
        ctVisits.forEach((vis) => {
            let item = <MdsSelectionList>{ value: vis.patientVisitMasterId, viewValue: vis.visitName };
            this.clinTrakVisitsPerGroupsForView[mappedVisit.visitName.visitGroup.name].push(item);
        });

        let item = this.studyMappedVisits.find((x) => x.visitName.name == mappedVisit.visitName.name);
        item.patientVisitsMaster = [];
    }

    expansionPanelchangeEvent(event: MatCheckboxChange, expansionPanelIndex: number, groupName: string) {
        let formGroupPerName = this.baseFormGroup.controls[`${groupName}FormGroup`] as FormGroup;
        let formArray = formGroupPerName.controls.pcsVisitsArray as FormArray<FormControl<boolean>>;
        if (event.checked) {
            formArray.controls.forEach((fc, index) => {
                if (expansionPanelIndex !== index) fc.disable();
            });
            this.selectedPcsVisit = event.source.id;

            Object.keys(this.baseFormGroup.controls).forEach((key) => {
                if (key !== `${groupName}FormGroup`) {
                    let formGroupPerName = this.baseFormGroup.controls[key] as FormGroup;
                    let formArray = formGroupPerName.controls.pcsVisitsArray as FormArray<FormControl<boolean>>;
                    formArray.controls.forEach((control) => {
                        control.setValue(false);
                        control.enable();
                        control.updateValueAndValidity();
                    });
                }
            });
        } else {
            this.selectedPcsVisit = null;
            formArray.controls.forEach((fc, index) => {
                if (expansionPanelIndex !== index) fc.enable();
            });
        }

        this.pcsVisitsFormArray.updateValueAndValidity();
    }

    getFormArrayByGroupName(groupName: string): FormArray<FormControl<string>> {
        let formGroupPerName = this.baseFormGroup.controls[`${groupName}FormGroup`] as FormGroup;
        let formArray = formGroupPerName.controls.pcsVisitsArray as FormArray<FormControl<string>>;
        return formArray;
    }

    confirm() {
        this.study.studyInfo.studyMappedVisits = this.studyMappedVisits;
        this.dialogRef.close({ mappedVisits: this.studyMappedVisits, id: 'studyMappedVisits' });
    }
}
